export class StatementAPIMockData {
	static statement_response_1 = {
		statementId: '12345',
		name: 'Placeholder static 1',
		transactionTql: null,
		balanceTql: {
			type: 'AST',
			expression: {
				lhs: {
					field: 'account',
				},
				op: 'in',
				rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
			},
		},
		recurring: false,
		currency: 'USD',
		cadence: 'monthly',
		lastDayOfWeek: 'sunday',
		startDate: '2024-06-01',
		endDate: '2024-06-30',
		rounding: 'one',
		includeWeekends: true,
		lastUpdated: '2024-06-30',
		preferences: {
			grouping: null,
			columns: [
				{
					displayName: null,
					sortKey: 'date',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'accountNumber',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'institutionName',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'description',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'currency',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'amount',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'amountConverted',
					isVisible: false,
				},
				{
					displayName: null,
					sortKey: 'status',
					isVisible: false,
				},
			],
			accountsBalancePreferences: {
				'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac': {
					openingBalanceProperty: 'currentDayCurrentAvailable',
				},
				'd768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006': {
					openingBalanceProperty: 'currentDayOpeningAvailable',
				},
			},
			transactionsSameAsBalances: true,
		},
		includedAccounts: ['eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac', 'd768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006'],
		numberOfVersions: 3,
	};

	static statement_response_2 = {
		statementId: '21345',
		name: 'Placeholder test crud 2',
		balanceTql: {
			type: 'AST',
			expression: {
				lhs: {
					field: 'account',
				},
				op: 'in',
				rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
			},
		},
		transactionTql: {
			type: 'AST',
			expression: {
				lhs: {
					lhs: {
						field: 'account',
					},
					op: 'in',
					rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
				},
				op: 'and',
				rhs: {
					lhs: {
						field: 'tag',
					},
					op: 'in',
					rhs: ['4a41c17d-d95b-4b3f-af49-7e0904fa7f97'],
				},
			},
		},
		recurring: false,
		currency: 'USD',
		cadence: 'monthly',
		lastDayOfWeek: 'sunday',
		startDate: '2024-07-01',
		endDate: '2024-07-31',
		rounding: 'none',
		includeWeekends: true,
		lastUpdated: '2024-07-31',
		preferences: {
			grouping: null,
			columns: [
				{
					displayName: null,
					sortKey: 'date',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'accountNumber',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'institutionName',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'description',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'currency',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'amount',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'amountConverted',
					isVisible: false,
				},
				{
					displayName: null,
					sortKey: 'status',
					isVisible: false,
				},
			],
			accountsBalancePreferences: {
				'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac': {
					openingBalanceProperty: 'currentDayCurrentAvailable',
				},
				'd768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006': {
					openingBalanceProperty: 'currentDayOpeningAvailable',
				},
			},
			transactionsSameAsBalances: false,
		},
		includedAccounts: ['eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac', 'd768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006'],
		numberOfVersions: 24,
	};

	static statement_response_4 = {
		statementId: '444444',
		name: 'New Placeholder Statement 4',
		balanceTql: {
			type: 'AST',
			expression: {
				lhs: {
					field: 'account',
				},
				op: 'in',
				rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
			},
		},
		transactionTql: {
			type: 'AST',
			expression: {
				lhs: {
					lhs: {
						field: 'account',
					},
					op: 'in',
					rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
				},
				op: 'and',
				rhs: {
					lhs: {
						field: 'tag',
					},
					op: 'in',
					rhs: ['4a41c17d-d95b-4b3f-af49-7e0904fa7f97'],
				},
			},
		},
		recurring: false,
		currency: 'USD',
		cadence: 'monthly',
		lastDayOfWeek: 'Sunday',
		startDate: '2024-07-01',
		endDate: '2024-07-31',
		rounding: 'none',
		includeWeekends: true,
		lastUpdated: '2024-07-31',
		preferences: {
			grouping: null,
			columns: [
				{
					displayName: null,
					sortKey: 'date',
					isVisible: true,
				},
				{
					displayName: 'Acc#',
					sortKey: 'accountNumber',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'institutionName',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'description',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'currency',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'amount',
					isVisible: true,
				},
				{
					displayName: null,
					sortKey: 'amountConverted',
					isVisible: false,
				},
				{
					displayName: null,
					sortKey: 'status',
					isVisible: false,
				},
			],
			accountsBalancePreferences: {
				'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac': {
					openingBalanceProperty: 'currentDayCurrentAvailable',
				},
				'd768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006': {
					openingBalanceProperty: 'currentDayOpeningAvailable',
				},
			},
			transactionsSameAsBalances: true,
		},
		includedAccounts: ['eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac', 'd768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006'],
		numberOfVersions: 3,
	};

	static statement_version_0 = {
		statementVersionId: '34565',
		statementId: '12345',
		statementVersionDataId: '45678',
		name: null,
		balanceTql: {
			type: 'AST',
			expression: {
				lhs: {
					field: 'account',
				},
				op: 'in',
				rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
			},
		},
		transactionTql: {
			type: 'AST',
			expression: {
				lhs: {
					lhs: {
						field: 'account',
					},
					op: 'in',
					rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
				},
				op: 'and',
				rhs: {
					lhs: {
						field: 'tag',
					},
					op: 'in',
					rhs: ['4a41c17d-d95b-4b3f-af49-7e0904fa7f97'],
				},
			},
		},
		recurring: true,
		currency: 'USD',
		cadence: 'monthly',
		cadenceStartDate: '2024-06-01',
		cadenceEndDate: '2024-06-30',
		rounding: 'none',
		status: 'processing',
		includeWeekends: true,
		type: 'generated',
	};

	static statement_version_1 = {
		statementVersionId: '34567',
		statementId: '12345',
		statementVersionDataId: '45678',
		name: null,
		balanceTql: {
			type: 'AST',
			expression: {
				lhs: {
					field: 'account',
				},
				op: 'in',
				rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
			},
		},
		transactionTql: {
			type: 'AST',
			expression: {
				lhs: {
					lhs: {
						field: 'account',
					},
					op: 'in',
					rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
				},
				op: 'and',
				rhs: {
					lhs: {
						field: 'tag',
					},
					op: 'in',
					rhs: ['4a41c17d-d95b-4b3f-af49-7e0904fa7f97'],
				},
			},
		},
		recurring: true,
		currency: 'USD',
		cadence: 'monthly',
		cadenceStartDate: '2024-06-01',
		cadenceEndDate: '2024-06-30',
		rounding: 'none',
		status: 'complete',
		includeWeekends: true,
		type: 'generated',
	};

	static statement_version_2 = {
		statementVersionId: '65678',
		statementId: '21345',
		statementVersionDataId: 56783,
		name: 'custom name 123',
		balanceTql: {
			type: 'AST',
			expression: {
				lhs: {
					field: 'account',
				},
				op: 'in',
				rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
			},
		},
		transactionTql: {
			type: 'AST',
			expression: {
				lhs: {
					lhs: {
						field: 'account',
					},
					op: 'in',
					rhs: ['d768f02690c21bbfd730fb877d3969a2183d9a26d8b2289562a5e313204d0006', 'eb9e308a3cfc18b544d24c77305f94c0ea010dee14e029ee76691701c36fcaac'],
				},
				op: 'and',
				rhs: {
					lhs: {
						field: 'tag',
					},
					op: 'in',
					rhs: ['4a41c17d-d95b-4b3f-af49-7e0904fa7f97'],
				},
			},
		},
		recurring: true,
		currency: 'USD',
		cadenceStartDate: '2024-07-01',
		cadenceEndDate: '2024-07-31',
		rounding: 'none',
		status: 'complete',
		type: 'generated',
	};

	static version_data_1 = {
		statementVersionId: '456789',
		statementVersionDataId: '45678',
		data: {
			transactions: [],
			balances: [],
			accounts: [],
			calculatedOpeningBalance: 1000000,
			calculatedClosingBalance: 2000000,
			reportedOpeningBalance: 1000000,
			reportedClosingBalance: 2000000,
			closingBalanceMissing: false,
			missingBalanceAccountIds: [],
		},
	};

	static version_data_2 = {
		statementVersionId: '65678',
		statementVersionDataId: '56783',
		data: {
			transactions: [],
			balances: [],
			accounts: [],
			calculatedOpeningBalance: 2000000,
			calculatedClosingBalance: 3000000,
			reportedOpeningBalance: 2000000,
			reportedClosingBalance: 3000000,
			closingBalanceMissing: false,
			missingBalanceAccountIds: [],
		},
	};

	static getAllStatements() {
		return {
			statements: [
				this.statement_response_1,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
				this.statement_response_2,
			],
		};
	}

	static getStatement1() {
		return {
			...this.statement_response_1,
			latestVersion: { ...this.statement_version_1, versionData: { ...this.version_data_1 } },
		};
	}

	static getStatement2() {
		return {
			...this.statement_response_2,
			latestVersion: { ...this.statement_version_2, versionData: { ...this.version_data_2 } },
		};
	}

	static getStatement4() {
		return {
			...this.statement_response_4,
			latestVersion: { ...this.statement_version_2, versionData: { ...this.version_data_2 } },
		};
	}

	static getStatement1Versions() {
		return { statementVersions: [this.statement_version_0, this.statement_version_1, this.statement_version_2] };
	}

	static getStatement2Versions() {
		return { statementVersions: [this.statement_version_0, this.statement_version_1, this.statement_version_2] };
	}
}
