import {
	adjustDualBalanceTypes,
	AnalysisBalanceProperty,
	AnalysisBalanceValue,
	AnalysisDataRoot,
	AnalysisDataAggregation,
	AnalysisDataSettings,
	AnalysisDisplaySettings,
	AnalysisTimeFrame,
	AnalysisTransactionValue,
	AnalysisType,
	mapGroupTypeToTQLField,
	AnalysisOtherGroupValue,
	CustomTrendMetric,
} from './analysis.model';
import { ReconciliationReport } from './reconciliation.model';
import { AccountTagType } from '../../transactions/models/tag.model';
import { Snack, SnackCarousel, SnackSize, SnackType } from 'src/app/shared/models/snacks.model';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { FilterMap, FilterOption } from 'src/app/shared/models/abstract-filter.model';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { ReconciliationSnack } from './reconciliation-snack-view.model';
import { IForecast } from '../../forecasts/models/forecast-forecast-response.model';
import { ItemsTab, ListItem } from 'src/app/shared/models/itemsTab.model';
import { CalendarSettings, RollingType, ToDateOption } from 'src/app/shared/models/date-range-picker.model';
import { CustomMenuOption } from '@trovata/app/shared/models/custom-menu.model';
import { BalanceAnalysisOption, BalanceAnalysisOptions } from './analysis-chart-view-model';
import { TQLPayload, TQLValuesDict } from '@trovata/app/shared/models/tql.model';
import { DateTime } from 'luxon';
import { GenericOption } from '@trovata/app/shared/models/option.model';

export enum ReportTypeID {
	reconciliation = 'caba640c-e231-4f16-b477-ecfd283942c8',
	userGenerated = 'b4261ae8-ea39-4646-948a-54430b38b2aa',
	ocReconciliation = '9d865a59-a2e6-4db2-ba86-c2710d8f04aa',
	userAccess = 'ca168730-9f26-43b9-85b8-33d44141b816',
	transactionCount = 'acf36494-5f53-43e7-acb5-98bd74a730e9',
	cashBalances = 'd9a19811-a78d-49e1-9658-f2e082130933',
	breakdown = '2608432f-5c7f-4853-96c6-919978ec7f2c',
}

export class ReportV4 {
	reportType: ReportType;
	name: string;
	reportId: string;
	preferences?: Object;
	createdDate?: string;
	lastModifiedDate?: string;
	lastModifiedUser?: string;
	elements?: ReportV4Element[];
	flipped?: boolean;
	reportData: ReportV4ElementData[];
	errorMessage?: string;
	reconId?: string;
}

export enum ReportType {
	report = 'report',
	recon = 'recon',
}

export interface ReportV4Parameters extends AnalysisDataSettings {
	tql: TQLPayload;
	customMetric?: CustomTrendMetric;
}

export interface ReportV4Element {
	elementId?: string;
	type: AnalysisType;
	parameters: ReportV4Parameters;
	preferences?: ElementPreferences;
}

export interface ElementPreferences {
	displaySettings: AnalysisDisplaySettings;
	calendarSettings: CalendarSettings;
	visualType: ElementType;
	elementOrder: number;
	balanceProperty: string;
}

export enum ElementType {
	grid = 'grid',
	chart = 'chart',
}

export class ReportV4ElementData {
	elementId: string;
	data?: AnalysisDataRoot<AnalysisTransactionValue> | AnalysisDataRoot<AnalysisBalanceValue>;
}

export class ElementPreferencesLegacy {
	cadence: Cadence;
	periods: number;
	endDate: string;
	startDate: string;
	datesLastModifiedDate: string;
	rollingType: RollingType;
	toDateOption: ToDateOption;
	customOptionCadence: AnalysisTimeFrame;
	customOptionPeriods: number;
	rollingDates: boolean;
	rollingEndDateOffset: number;
	rollingStartDateOffset: number;
	weekends: boolean;
	usePeriods: boolean;
	graphType: string;
	balanceType: AnalysisBalanceProperty;
	visualType: string;
	elementOrder: number;
	netToggleValue: boolean;
	userOrdered: any = { order: [] };
	exclusiveTagAggregation: boolean;
	rounding: number;
	timeFrame?: AnalysisTimeFrame;
	roundingOption?: RoundingOption;
	currencyOverride?: string;
}

enum GenericGroupings {
	currency = 'Currency',
	institutionId = 'Institution',
	accountId = 'Account',
}

enum AccountTagGroupings {
	entity = AccountTagType.entity,
	region = AccountTagType.region,
	division = AccountTagType.division,
}

export const BalanceGroupings = { ...GenericGroupings, ...AccountTagGroupings };

export enum TransactionSpecificGroupings {
	type = 'Credit/Debit',
	tag = 'Tag',
	excludeTags = 'Exclude Tags',
	q = 'Text Search',
}

export const TransactionGroupings = {
	...GenericGroupings,
	...TransactionSpecificGroupings,
	...AccountTagGroupings,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ReportGraphTypes = [
	{ name: 'Bar Chart', value: 'column', icon: 'bar_chart' },
	{ name: 'Stacked Bar Chart', value: 'stackedbar', icon: 'stacked_bar_chart' },
	{ name: 'Line Chart', value: 'line', icon: 'show_chart' },
	{ name: 'Scatter Plot', value: 'scatter', icon: 'scatter_plot' },
	{ name: 'Pie Chart', value: 'pie', icon: 'pie_chart' },
];

export const roundingOptions: RoundingOption[] = [
	{ name: 'No Rounding', value: 0, symbol: '' },
	{ name: 'Whole Number', value: 1, symbol: '' },
	{ name: 'Thousands', value: 1000, symbol: 'K' },
	{ name: 'Millions', value: 1000000, symbol: 'M' },
	{ name: 'Billions', value: 1000000000, symbol: 'B' },
];

export class RoundingOption {
	name: string;
	value: number;
	symbol: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ReportTemplates = [
	{ name: 'Graph', value: ['graph'] },
	{ name: 'Grid', value: ['grid'] },
	{ name: 'Graph, Grid', value: ['graph', 'grid'] },
	{ name: 'Grid, Graph', value: ['grid', 'graph'] },
];

export class ReportTabV3 {
	name: string;
	id: string;
	layout: string;
	reports: (ReportV4 | ReconciliationReport)[];
	flippedReports: ReportTabObject[];
}

export class ReportTabData {
	name: string;
	id: string;
	layout: string;
	reportIds: string[];
	flippedReports: string[];
}

export class ReportTabDataV2 {
	name: string;
	id: string;
	layout: string;
	reportIds: ReportTabObject[];
	flippedReports: ReportTabObject[];
}

export enum ReportTabObjectType {
	report = 'Report',
	reconReport = 'ReconciliationReport',
}

export class ReportTabObject {
	type: ReportTabObjectType;
	id: string;
	constructor(type: ReportTabObjectType, id: string) {
		this.type = type;
		this.id = id;
	}
}

export class ReportData {
	cashFlowTypes: CashFlowTypeData;
	balances: BalancesData[];
	periodData: TransactionCountData[];
	totalTransactionCount: number;
	vectorReport: any;
}

export class Transactions {
	creditAmount: number;
	creditAmountNative: number;
	debitAmount: number;
	debitAmountNative: number;
	epoch?: Date;
}

export class Balances {
	accountIds: string[];
	bankClosingAvailable: number;
	bankClosingAvailableConverted: number;
	bankClosingLedger: number;
	bankClosingLedgerConverted: number;
	bankCurrentAvailable: number;
	bankCurrentAvailableConverted: number;
	bankOpeningAvailable: number;
	bankOpeningAvailableConverted: number;
	bankOpeningLedger: number;
	bankOpeningLedgerConverted: number;
	containsIsCalculated: boolean;
	containsIsFilled: boolean;
	date: Date;
	trovataClosingBalance: number;
	trovataClosingBalanceConverted: number;
	trovataOpeningBalance: number;
	trovataOpeningBalanceConverted: number;
}

export class Summary {
	creditAmount: number;
	creditAmountNative: number;
	debitAmount: number;
	debitAmountNative: number;
}

export class CashFlowTypeData {
	tag: TagReportData[];
	other: SpendData[];
	totalReportSpend: SpendData[];
	total: SpendData[];
}

export class TagReportData {
	identifier: string;
	periodData: SpendData[];
}

export class SpendData {
	period: PeriodData;
	day: string;
	net: number;
	credits: number;
	debits: number;
}

export class BalancesData {
	type: string;
	breakdown: BalanceBreakdownData[];
	total: BalanceBreakdown[];
}

export class BalanceBreakdownData {
	parameter: string;
	periodData: BalanceBreakdown[];
}

export class BalanceBreakdown {
	period: PeriodData;
	openingBalance: number;
	closingBalance: number;
	currency: string;
}

export class TransactionCountData {
	period: PeriodData;
	transactionCount: number;
}

export class PeriodData {
	startDate: string;
	endDate: string;
}

export type ReportsDict = Map<string, ReportV4 | ReconciliationReport>;
export class ReportsPage {
	reportsTabs: ReportsTab[];
	allReportsDict: ReportsDict;

	constructor(
		private reports: ReportV4[],
		private reconReports: ReconciliationReport[],
		preferences: ReportsPagePreferences,
		private currencyDict: CurrencyDict,
		private filterMap: FilterMap,
		private tqlValuesDict: TQLValuesDict,
		private locale: string
	) {
		this.reportsTabs = [];
		this.allReportsDict = new Map();
		this.reports.forEach((report: ReportV4) => {
			this.allReportsDict[report.reportId] = report;
		});
		this.reconReports.forEach((reconReport: ReconciliationReport) => {
			this.allReportsDict[reconReport.reconId] = reconReport;
		});
		preferences.tabs.forEach((reportsTab: ReportsTabPreferences) => {
			this.createReportsTab(reportsTab);
		});
	}
	private createReportsTab(reportsTabPreferences: ReportsTabPreferences): void {
		const tabReportsDict: ReportsDict = new Map();
		reportsTabPreferences.reports.forEach((report: ReportPreference) => {
			tabReportsDict[report.id] = this.allReportsDict[report.id];
		});
		this.reportsTabs.push(new ReportsTab(tabReportsDict, reportsTabPreferences, this.currencyDict, this.tqlValuesDict, this.locale));
	}
}

export enum DefaultReportTabs {
	AllReports = 'All Reports',
	AllRecons = 'All Reconciliations',
	AllForecasts = 'All Forecasts',
}
export class ReportsTab extends ItemsTab {
	constructor(
		private reportsDict: ReportsDict,
		private reportsTabPreferences: ReportsTabPreferences,
		private currencyDict: CurrencyDict,
		private tqlValuesDict: TQLValuesDict,
		private locale: string
	) {
		super(reportsTabPreferences.name, reportsTabPreferences.tabId, reportsTabPreferences.editable);
		this.createReportCarousel();
		this.createReportList();
	}

	private createReportList(): void {
		this.listItems = [];
		this.reportsTabPreferences.reports
			.filter((reportPref: ReportPreference) => this.reportsDict[reportPref.id])
			.forEach((reportPref: ReportPreference) => {
				try {
					const isFavorite: boolean = this.reportsTabPreferences.favorites.includes(reportPref.id);
					if (this.reportsDict[reportPref.id].reportId) {
						this.listItems.push(new ReportListItem(this.reportsDict[reportPref.id], isFavorite));
					} else if (this.reportsDict[reportPref.id].reconId) {
						this.listItems.push(new ReconListItem(this.reportsDict[reportPref.id], isFavorite));
					}
				} catch (err) {
					throw err;
				}
			});
		if (!this.reportsTabPreferences.editable) {
			this.listItems.sort((a: ListItem, b: ListItem) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
		}
	}

	private createReportCarousel(): void {
		this.snacks = [];
		this.reportsTabPreferences.favorites
			.filter((id: string) => this.reportsDict[id])
			.forEach((id: string) => {
				try {
					const flipped: boolean = this.reportsTabPreferences.flipped.includes(id);
					if (this.reportsDict[id].reportId) {
						this.snacks.push(new ReportSnack(this.reportsDict[id], this.currencyDict, this.tqlValuesDict, this.locale, flipped));
					} else if (this.reportsDict[id].reconId) {
						this.snacks.push(new ReconciliationSnack(this.reportsDict[id], this.currencyDict, this.locale, this.tqlValuesDict, flipped));
					}
				} catch (err) {
					throw err;
				}
			});
		this.snackCarousel = new SnackCarousel(this.snacks, null, this.reportsTabPreferences.tabId, SnackSize.large);
	}
}

export class TabEvent {
	action: TabEventAction;
	tabId: string;
	listItem: ListItem;
	newTabName?: string;
	groupTabIndex?: number;
	constructor(action: TabEventAction, listItem?: ListItem, tabId?: string, newTabName?: string, groupTabIndex?: number) {
		this.action = action;
		this.listItem = listItem;
		this.tabId = tabId;
		this.newTabName = newTabName;
		this.groupTabIndex = groupTabIndex;
	}
}

export enum TabEventAction {
	removeFromTab = 'removeFromTab',
	addToTab = 'addToTab',
	createAndAdd = 'createAndAdd',
	createTab = 'createTab',
	deleteTab = 'deleteTab',
	addFavorite = 'addFavorite',
	removeFavorite = 'removeFavorite',
	sort = 'sortItems',
}

export class ReportListItem extends ListItem {
	report: ReportV4;
	constructor(report: ReportV4, isFavorite?: boolean) {
		super(
			report.name,
			report.elements[0]?.parameters?.cadence,
			report.createdDate,
			ReportTabObjectType.report,
			report.elements[0].type,
			report.reportId,
			isFavorite
		);
		this.report = report;
		this.itemType = ReportTabObjectType.report;
	}
}

export class ReconListItem extends ListItem {
	reconReport: ReconciliationReport;
	constructor(report: ReconciliationReport, isFavorite?: boolean) {
		super(report.name, report.cadence, report.creationDate, 'Reconciliation', report.groupBy, report.reconId, isFavorite);
		this.reconReport = report;
		this.itemType = ReportTabObjectType.reconReport;
	}
}

export class ForecastListItem extends ListItem {
	forecast: IForecast;
	clickable: boolean;
	menu: boolean;
	menuOptions: CustomMenuOption[];
	selected: boolean;
	disabled: boolean;

	constructor(forecast: IForecast, isFavorite?: boolean) {
		super(forecast?.name, Cadence[forecast?.cadence], forecast?.calendarSettings?.startDate, SnackType.forecastV3, '', forecast?.forecastId, isFavorite);
		this.clickable = true;
		this.forecast = forecast;
		this.menu = true;
		this.isFavorite = isFavorite;
		this.menuOptions = [new CustomMenuOption('view', 'View', true, false), new CustomMenuOption('delete', 'Delete', true, false)];
	}
}

export class ReportSnack extends Snack {
	// pre data
	name: string;
	report: ReportV4;
	type: SnackType.report = SnackType.report;

	// post data
	title: string;
	subTitle: string;
	dataItems: SnackDataItem[];
	altDataItems: GroupBreakdownItem[];
	cadence: string;

	pieChartDate: string;
	dataSource: AnalysisType;

	chartElement: ReportV4Element;
	chartElementData: ReportV4ElementData;
	chartPreferences: ElementPreferences;

	balanceTypes: BalanceAnalysisOption[] = [...new BalanceAnalysisOptions().primaryBalanceOptions, ...new BalanceAnalysisOptions().otherBalanceOptions];

	currencyDict: CurrencyDict;

	hasData: boolean;
	constructor(
		report: ReportV4,
		currencyDict: CurrencyDict,
		private tqlValuesDict: TQLValuesDict,
		private locale: string,
		flipped?: boolean
	) {
		super(SnackSize.large, SnackType.report, report.reportId, flipped);
		this.name = report.name;
		this.title = '';
		this.report = report;
		this.dataItems = [];
		this.altDataItems = [];
		this.currencyDict = currencyDict;
		if (report.reportData) {
			this.reportData = this.report.reportData;
		}
	}

	set reportData(reportData: ReportV4ElementData[]) {
		if (reportData && reportData.length) {
			this.report.reportData = reportData;
			this.chartElement = this.report?.elements?.length > 0 ? this.report.elements[0] : null;
			this.chartElementData = reportData[0];
			this.initChartElementPreferences();
			this.processElementData(this.chartElement, this.chartElementData);
			this.hasData = true;
		}
	}

	private initChartElementPreferences(): void {
		const chartPrefs: ElementPreferences = this.chartElement.preferences;
		const elementPreferences: ElementPreferences = {
			calendarSettings: chartPrefs?.calendarSettings ?? null,
			displaySettings: chartPrefs?.displaySettings ?? null,
			visualType: chartPrefs?.visualType ?? null,
			elementOrder: chartPrefs?.elementOrder ?? null,
			// TODO set to something different probably
			balanceProperty: '',
		};
		this.chartPreferences = elementPreferences;
	}

	private processElementData(element: ReportV4Element, elementData: ReportV4ElementData): void {
		if (element && elementData && elementData.data && elementData.data?.summary.length) {
			const currencyCode: string = elementData.data.currencyConverted;
			const currency: Currency = this.currencyDict[currencyCode];
			const cadence: Cadence = element.parameters?.cadence || Cadence.daily;
			let date: string;
			this.dataSource = element.type;
			if (elementData.data?.type === AnalysisType.transactions) {
				this.setTransactionBreakdownItem(elementData.data, cadence === Cadence.daily, currency);
			} else if (elementData.data?.type === AnalysisType.balances) {
				const balanceType: string = adjustDualBalanceTypes(element.parameters.balanceProperty);
				this.setBalanceBreakdownItem(elementData.data, cadence === Cadence.daily, balanceType, currency);
				// TODO do we need this?
				// 	this.pieChartDate = date;
			}
			if (date) {
				this.title += ' - ' + date;
			}
			const startingDate: DateTime = DateTime.fromFormat(this.chartElementData.data?.summary[0].date, 'yyyy-MM-dd');
			const endingDate: DateTime = DateTime.fromFormat(this.chartElementData.data?.summary[this.chartElementData.data?.summary.length - 1].date, 'yyyy-MM-dd');
			if (startingDate.hasSame(endingDate, 'year')) {
				this.subTitle = `${startingDate.toLocaleString({ month: 'long', day: 'numeric' })} - ${endingDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}`;
			} else {
				this.subTitle = `${startingDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })} - ${endingDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}`;
			}
		}
	}

	private getBalanceTotal(agg: AnalysisDataAggregation<AnalysisBalanceValue>, prop: string): number {
		if (agg && agg.summary && agg.summary.length > 0) {
			return agg.summary[agg.summary.length - 1][prop] || 0;
		} else if (agg && agg.aggregation && agg.aggregation.length > 0) {
			let total: number = 0;
			agg.aggregation.forEach((subAgg: AnalysisDataRoot<AnalysisBalanceValue>) => {
				total += this.getBalanceTotal(subAgg, prop);
			});
			return total;
		} else {
			return 0;
		}
	}

	private getTransactionTotal(agg: AnalysisDataAggregation<AnalysisTransactionValue>, prop: string, isDaily: boolean): number {
		if (agg && agg.summary && agg.summary.length > 0) {
			return agg.summary[agg.summary.length - 1][prop] || 0;
		} else if (agg && agg.aggregation && agg.aggregation.length > 0) {
			let total: number = 0;
			agg.aggregation.forEach((subAgg: AnalysisDataRoot<AnalysisTransactionValue>) => {
				total += this.getTransactionTotal(subAgg, prop, isDaily);
			});
			return total;
		} else {
			return 0;
		}
	}

	private setBalanceBreakdownItem(data: AnalysisDataRoot<AnalysisBalanceValue>, isDaily: boolean, balanceType: string, currency: Currency): void {
		if (!data.summary?.length || data.aggregation?.length <= 0) {
			if (data.summary?.length) {
				const totalBreakdownItem: GroupBreakdownItem = new GroupBreakdownItem();
				totalBreakdownItem.title = 'All Balances';
				totalBreakdownItem.netValue = data.summary[data.summary.length - 1][balanceType];
				totalBreakdownItem.percentage = 100;
				totalBreakdownItem.percentage = parseFloat(totalBreakdownItem.percentage.toFixed(2));
				totalBreakdownItem.currency = currency;
				this.altDataItems = [totalBreakdownItem];
			}
			return;
		}

		let totalAccountBalance: number | null = data.summary[data.summary.length - 1][balanceType];
		if (totalAccountBalance === null) {
			totalAccountBalance = data.aggregation.reduce(
				(accumulator: number, bal: AnalysisDataRoot<AnalysisBalanceValue>) => accumulator + this.getBalanceTotal(bal, balanceType),
				0
			);
		}

		this.altDataItems = data.aggregation.map((bal: AnalysisDataRoot<AnalysisBalanceValue>) => {
			const grouping: GroupBreakdownItem = new GroupBreakdownItem();
			grouping.title = this.groupDisplay(bal.key, bal.value, data.type);
			grouping.netValue = this.getBalanceTotal(bal, balanceType);
			grouping.percentage = (grouping.netValue / totalAccountBalance) * 100.0;
			grouping.percentage = parseFloat(grouping.percentage.toFixed(2));
			grouping.currency = currency;
			return grouping;
		});

		this.altDataItems.sort((a: GroupBreakdownItem, b: GroupBreakdownItem) => b.percentage - a.percentage);
	}

	private setTransactionBreakdownItem(data: AnalysisDataRoot<AnalysisTransactionValue>, isDaily: boolean, currency: Currency): void {
		if (!data.summary?.length) {
			return;
		}
		this.altDataItems = [];

		if (data.aggregation && data.aggregation.length > 0) {
			const toatlValuesSum: number = data.aggregation.reduce(
				(accumulator: number, bal: AnalysisDataAggregation<AnalysisTransactionValue>) => accumulator + Math.abs(this.getTransactionTotal(bal, 'net', isDaily)),
				0
			);
			data.aggregation.forEach((trans: AnalysisDataAggregation<AnalysisTransactionValue>) => {
				const grouping: GroupBreakdownItem = new GroupBreakdownItem();
				grouping.title = this.groupDisplay(trans.key, trans.value, data.type);
				grouping.netValue = this.getTransactionTotal(trans, 'net', isDaily) || 0;
				grouping.percentage = (Math.abs(grouping.netValue) / toatlValuesSum) * 100.0;
				grouping.percentage = parseFloat(grouping.percentage.toFixed(2));
				grouping.currency = currency;
				this.altDataItems.push(grouping);
			});
		} else {
			const creditTotal: number = data.summary.reduce((a: number, b: AnalysisTransactionValue) => a + b.credit, 0);
			const debitTotal: number = data.summary.reduce((a: number, b: AnalysisTransactionValue) => a + b.debit, 0);

			const transactionsVolumeTotal: number = Math.abs(creditTotal + debitTotal);
			const grouping: GroupBreakdownItem = new GroupBreakdownItem();
			grouping.title = 'Credit';
			grouping.netValue = creditTotal;
			grouping.percentage = (creditTotal / transactionsVolumeTotal) * 100.0;
			grouping.percentage = parseFloat(grouping.percentage.toFixed(2));
			grouping.currency = currency;
			this.altDataItems.push(grouping);

			const debitGrouping: GroupBreakdownItem = new GroupBreakdownItem();
			debitGrouping.title = 'Debit';
			debitGrouping.netValue = -1 * Math.abs(debitTotal);
			debitGrouping.percentage = (debitTotal / transactionsVolumeTotal) * 100.0;
			debitGrouping.percentage = parseFloat(debitGrouping.percentage.toFixed(2));
			debitGrouping.currency = currency;
			this.altDataItems.push(debitGrouping);
		}

		this.altDataItems.sort((a: GroupBreakdownItem, b: GroupBreakdownItem) => b.percentage - a.percentage);
	}

	private groupDisplay(groupType: string, groupId: string, type: AnalysisType): string {
		const filter: FilterOption = this.tqlValuesDict[mapGroupTypeToTQLField(groupType)]?.values?.find((option: GenericOption) => option.id === groupId);
		if (filter) {
			return filter.displayValue;
		} else if (groupId === AnalysisOtherGroupValue) {
			return 'Other ' + (type === AnalysisType.balances ? 'Accounts' : 'Transactions');
		} else if (!groupId) {
			return 'All ' + (type === AnalysisType.balances ? 'Accounts' : 'Transactions');
		} else {
			return groupId;
		}
	}
}

export interface ReportsPagePreferences {
	tabs: ReportsTabPreferences[];
}

export interface ReportsTabPreferences {
	tabId: string;
	name: string;
	editable: boolean;
	favorites: string[];
	flipped: string[];
	reports: ReportPreference[];
}

export interface ReportPreference {
	id: string;
	type: ReportTabObjectType;
}

export class SnackDataItem {
	title: string = '';
	displayValue: string;

	constructor(title: string, displayValue: string) {
		this.title = title;
		this.displayValue = displayValue;
	}
}
export class GroupBreakdownItem {
	title: string;
	percentage: number;
	netValue: number;
	currency: Currency;
}

export interface ReportCreatedEvent {
	tabId: string;
	report: ReportV4;
}
