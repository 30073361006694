import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ReportPreference, ReportsPagePreferences, ReportsTabPreferences, ReportTabObjectType } from 'src/app/features/reports/models/report.model';
import { firstValueFrom, Observable } from 'rxjs';
import { PreferencesState } from '../../store/state/preferences/preferences.state';
import { AllPreferences, SnackPreference } from 'src/app/shared/models/preferences.model';
import { Snack as DashBoardSnack, SnacksTabData, SnackPreference as DashboardSnackPref } from '../../../features/balances/models/snacks.model';
import { SnackType } from 'src/app/shared/models/snacks.model';
import { onlyUnique } from '../../utils/onlyUnique';
import { ForecastsPagePreferences, ForecastsTabPreferences } from '@trovata/app/features/forecasts/models/forecast-forecast.model';
import { OverridePreferenceManually, UpdatePreferencesByKey } from '../../store/actions/preferences.actions';
import { UpdateStrategy } from '../../utils/update-strategy';
import { CashPositionPagePreferences } from 'src/app/features/cash-position/models/cash-position.model';
import { ItemType, UpdatedEvent } from '../../models/updated-events.model';
import { ReconciliationReportPreferences } from 'src/app/features/reports/models/reconciliation.model';
import { StatementsPagePreferences } from '@trovata/app/features/statements/models/statements.models';

@Injectable({
	providedIn: 'root',
})
export class PreferencesFacadeService {
	@Select(PreferencesState.preferences)
	preferences$: Observable<AllPreferences>;

	constructor(private store: Store) {}

	getPreferences(): Promise<AllPreferences> {
		return new Promise(resolve => {
			this.preferences$.subscribe(resp => {
				if (resp) {
					resolve(resp);
				}
			});
		});
	}

	getVisibleSnackIds(snackType: SnackType): Promise<string[]> {
		return new Promise(async (resolve, reject) => {
			try {
				const preferences: AllPreferences = await this.getPreferences();
				let visibleSnackIds: string[] = [];
				// carousel snacks
				const balancesCarouselPrefFirstThree: SnackPreference[] = preferences.balancesCarousel?.snacks?.slice(0, 3);
				const balancesCarouselReportSnackPrefs: SnackPreference[] = (balancesCarouselPrefFirstThree || []).filter(
					(filterSnack: SnackPreference) => filterSnack?.type === snackType
				);
				const balancesCarouselReportIds: string[] = balancesCarouselReportSnackPrefs.map((pref: SnackPreference) => pref.id);
				visibleSnackIds = [...visibleSnackIds, ...balancesCarouselReportIds];
				// dashboard snacks
				const dashboardTabPrefsFirstPage: SnacksTabData[] = preferences?.dashboardTabs?.slice(0, 1);
				const dashboardSnackPrefs: DashboardSnackPref[] = (dashboardTabPrefsFirstPage || [])
					.reduce((snacks, tab) => (snacks = [...snacks, ...tab.snacks]), [])
					.filter(
						(filterSnack: DashBoardSnack) =>
							filterSnack?.type === snackType || (snackType === SnackType.recon && <string>filterSnack?.type === 'reconciliation')
					);
				const dashboardPageIds: string[] = dashboardSnackPrefs.map((pref: DashboardSnackPref) => pref.id);
				visibleSnackIds = [...visibleSnackIds, ...dashboardPageIds];
				if (snackType === SnackType.report || snackType === SnackType.recon) {
					// reports page favorites
					const reportsPageCarouselPref: ReportsPagePreferences = preferences.reportsPagePreferences;
					const reportsPageFavoriteIds: string[] = reportsPageCarouselPref?.tabs
						.slice(0, 1)
						.reduce(
							(reports: string[], tab: ReportsTabPreferences) =>
								(reports = [
									...reports,
									...tab.favorites.slice(0, 3).filter((favoriteId: string) => tab.reports.find((report: ReportPreference) => report.id === favoriteId)),
								]),
							[]
						);
					visibleSnackIds = [...visibleSnackIds, ...reportsPageFavoriteIds];
				} else if (snackType === SnackType.forecastV3) {
					// forecast V3 page favorites
					const forecastPageCarouselPref: ForecastsPagePreferences = preferences.forecastsPagePreferences;
					const forecastPageFavoriteIds: string[] = forecastPageCarouselPref.tabs
						.slice(0, 1)
						.reduce((forecasts: string[], tab: ForecastsTabPreferences) => (forecasts = [...forecasts, ...tab.favorites].slice(0, 3)), []);
					visibleSnackIds = [...visibleSnackIds, ...forecastPageFavoriteIds];
				} else if (snackType === SnackType.cashPosition) {
					// cash position page favorites
					const cashPositionPageCarouselPref: CashPositionPagePreferences = preferences.cashPositionPagePreferences;
					if (cashPositionPageCarouselPref && cashPositionPageCarouselPref.cashPositionFavorites) {
						const cashPositionPageFavoriteIds: string[] = [...cashPositionPageCarouselPref.cashPositionFavorites.slice(0, 3)];
						visibleSnackIds = [...visibleSnackIds, ...cashPositionPageFavoriteIds];
					}
				}
				visibleSnackIds = visibleSnackIds.filter(onlyUnique);
				resolve(visibleSnackIds);
			} catch (error) {
				reject(error);
			}
		});
	}

	async removeDeletedItemPrefs(removedItem: UpdatedEvent): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const preferences: AllPreferences = await this.getPreferences();
				let updateRequests: Promise<void>[] = [];
				switch (removedItem.itemType) {
					case ItemType.account:
						updateRequests = this.removeFromAcctPrefs(removedItem, preferences, updateRequests);
						break;
					case ItemType.cashPosition:
						updateRequests = this.removeFromCashPosPrefs(removedItem, preferences, updateRequests);
						updateRequests = this.removeFromBalAndDashBoardSnackPrefs(removedItem, preferences, updateRequests, SnackType.cashPosition);
						break;
					case ItemType.currentCash:
						updateRequests = this.removeFromBalAndDashBoardSnackPrefs(removedItem, preferences, updateRequests, SnackType.currentCash);
						break;
					case ItemType.recon:
						updateRequests = this.removeFromReconPrefs(removedItem, preferences, updateRequests);
						updateRequests = this.removeFromReportSnackPrefs(removedItem, preferences, updateRequests, SnackType.recon, ReportTabObjectType.reconReport);
						updateRequests = this.removeFromBalAndDashBoardSnackPrefs(removedItem, preferences, updateRequests, SnackType.recon);
						break;
					case ItemType.report:
						updateRequests = this.removeFromReportSnackPrefs(removedItem, preferences, updateRequests, SnackType.report, ReportTabObjectType.report);
						updateRequests = this.removeFromBalAndDashBoardSnackPrefs(removedItem, preferences, updateRequests, SnackType.report);
						break;
					case ItemType.tag:
						updateRequests = this.removeFromTagPrefs(removedItem, preferences, updateRequests);
						updateRequests = this.removeFromBalAndDashBoardSnackPrefs(removedItem, preferences, updateRequests, SnackType.tag);
						break;
					case ItemType.forecastV3:
						updateRequests = this.removeFromForecastPrefs(removedItem, preferences, updateRequests);
						updateRequests = this.removeFromBalAndDashBoardSnackPrefs(removedItem, preferences, updateRequests, SnackType.forecastV3);
						break;
					case ItemType.statement:
						updateRequests = this.removeFromStatementsPrefs(removedItem, preferences, updateRequests);
						break;
				}
				if (updateRequests.length) {
					await Promise.all(updateRequests);
					resolve();
				} else {
					resolve();
				}
			} catch (err) {
				reject(err);
			}
		});
	}

	async getPreferenceByKey<T>(preferenceKey: string): Promise<T> {
		return new Promise(async (resolve, reject) => {
			try {
				const preferences: AllPreferences = await this.getPreferences();
				resolve(preferences[preferenceKey]);
			} catch (error) {
				reject(error);
			}
		});
	}

	updatePreferencesByKey(key: string, preferences: any, updateStrategy: UpdateStrategy = UpdateStrategy.pessimistic): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdatePreferencesByKey(key, preferences, updateStrategy)));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	overridePreferencesByKey(key: string, preferences: any): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new OverridePreferenceManually(key, preferences)));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private removeFromAcctPrefs(removedItem: UpdatedEvent, preferences: AllPreferences, currentRequests: Promise<void>[]): Promise<void>[] {
		if (preferences.accountDetailsChartData) {
			const accountDetailsChartDataCopy: any = JSON.parse(JSON.stringify(preferences.accountDetailsChartData));
			if (accountDetailsChartDataCopy?.[removedItem.itemId]) {
				delete accountDetailsChartDataCopy[removedItem.itemId];
				currentRequests.push(this.updatePreferencesByKey('accountDetailsChartData', accountDetailsChartDataCopy));
			}
			return currentRequests;
		} else {
			return [];
		}
	}

	private removeFromReconPrefs(removedItem: UpdatedEvent, preferences: AllPreferences, currentRequests: Promise<void>[]): Promise<void>[] {
		const reconReportPreferencesCopy: {
			[reconId: string]: ReconciliationReportPreferences;
		} = JSON.parse(JSON.stringify(preferences.reconReportPreferences));
		if (reconReportPreferencesCopy[removedItem.itemId]) {
			delete reconReportPreferencesCopy[removedItem.itemId];
			currentRequests.push(this.updatePreferencesByKey('reconReportPreferences', reconReportPreferencesCopy));
		}
		return currentRequests;
	}

	private removeFromCashPosPrefs(removedItem: UpdatedEvent, preferences: AllPreferences, currentRequests: Promise<void>[]): Promise<void>[] {
		const cashPositionPagePrefCopy: CashPositionPagePreferences = JSON.parse(JSON.stringify(preferences.cashPositionPagePreferences));
		if (
			cashPositionPagePrefCopy?.cashPositionFavorites?.findIndex((cashPositionId: string) => cashPositionId === removedItem.itemId) >= 0 ||
			(cashPositionPagePrefCopy?.cashPositionIndividualPreferences && cashPositionPagePrefCopy?.cashPositionIndividualPreferences[removedItem.itemId])
		) {
			delete cashPositionPagePrefCopy.cashPositionIndividualPreferences[removedItem.itemId];
			cashPositionPagePrefCopy.cashPositionFavorites = cashPositionPagePrefCopy.cashPositionFavorites.filter(
				(cashPositionId: string) => cashPositionId !== removedItem.itemId
			);
			currentRequests.push(this.updatePreferencesByKey('cashPositionPagePreferences', cashPositionPagePrefCopy));
		}
		return currentRequests;
	}

	private removeFromTagPrefs(removedItem: UpdatedEvent, preferences: AllPreferences, currentRequests: Promise<void>[]): Promise<void>[] {
		const tagChartDataCopy = JSON.parse(JSON.stringify(preferences.tagChartData));
		if (tagChartDataCopy[removedItem.itemId]) {
			delete tagChartDataCopy[removedItem.itemId];
			currentRequests.push(this.updatePreferencesByKey('tagChartData', tagChartDataCopy));
		}
		return currentRequests;
	}

	private removeFromStatementsPrefs(removedItem: UpdatedEvent, preferences: AllPreferences, currentRequests: Promise<void>[]): Promise<void>[] {
		const statementsPagePref: StatementsPagePreferences = preferences.statementsPreferences;
		statementsPagePref.favorites = statementsPagePref.favorites.filter((id: string) => id !== removedItem.itemId);
		if (statementsPagePref.statementIndividualPreferences[removedItem.itemId]) {
			delete statementsPagePref.statementIndividualPreferences[removedItem.itemId];
		}
		currentRequests.push(this.updatePreferencesByKey('statementsPreferences', statementsPagePref));
		return currentRequests;
	}

	private removeFromForecastPrefs(removedItem: UpdatedEvent, preferences: AllPreferences, currentRequests: Promise<void>[]): Promise<void>[] {
		const forecastPageCarouselPref: ForecastsPagePreferences = preferences.forecastsPagePreferences;
		if (
			forecastPageCarouselPref.tabs.filter(
				(tab: ForecastsTabPreferences) => tab.forecastIds.filter((forecastId: string) => forecastId === removedItem.itemId).length
			).length
		) {
			const forecastPagePrefCopy: ForecastsPagePreferences = JSON.parse(JSON.stringify(forecastPageCarouselPref));
			forecastPagePrefCopy.tabs.forEach((tab: ForecastsTabPreferences) => {
				if (tab.forecastIds.findIndex((forecastId: string) => forecastId === removedItem.itemId) >= 0) {
					tab.forecastIds = tab.forecastIds.filter((forecastId: string) => removedItem.itemId !== forecastId);
					tab.favorites = tab.favorites.filter((forecastId: string) => forecastId !== removedItem.itemId);
				}
			});
			currentRequests.push(this.updatePreferencesByKey('forecastsPagePreferences', forecastPagePrefCopy));
		}
		const forecastsPagePreferencesCopy: any = preferences.forecastsPagePreferences;
		if (forecastsPagePreferencesCopy[removedItem.itemId]) {
			delete forecastsPagePreferencesCopy[removedItem.itemId];
			currentRequests.push(this.updatePreferencesByKey('forecastsPagePreferences', forecastsPagePreferencesCopy));
		}
		return currentRequests;
	}

	private removeFromReportSnackPrefs(
		removedItem: UpdatedEvent,
		preferences: AllPreferences,
		currentRequests: Promise<void>[],
		snackType: SnackType,
		reportTabType: ReportTabObjectType
	): Promise<void>[] {
		const reportsPageCarouselPref: ReportsPagePreferences = preferences.reportsPagePreferences;
		if (
			snackType &&
			reportTabType &&
			reportsPageCarouselPref?.tabs?.filter(
				(tab: ReportsTabPreferences) =>
					tab.reports.filter((report: ReportPreference) => report.type === reportTabType && removedItem.itemId === report.id).length
			).length
		) {
			const reportsPagePrefCopy: ReportsPagePreferences = JSON.parse(JSON.stringify(reportsPageCarouselPref));
			reportsPagePrefCopy.tabs.forEach((tab: ReportsTabPreferences) => {
				if (tab.reports.findIndex((report: ReportPreference) => report.type === reportTabType && removedItem.itemId === report.id) >= 0) {
					tab.reports = tab.reports.filter((report: ReportPreference) => report.type !== reportTabType || removedItem.itemId !== report.id);
					tab.favorites = tab.favorites.filter((id: string) => id !== removedItem.itemId);
				}
			});
			currentRequests.push(this.updatePreferencesByKey('reportsPagePreferences', reportsPagePrefCopy));
		}
		return currentRequests;
	}

	private removeFromBalAndDashBoardSnackPrefs(
		removedItem: UpdatedEvent,
		preferences: AllPreferences,
		currentRequests: Promise<void>[],
		snackType: SnackType
	): Promise<void>[] {
		if (snackType) {
			if (
				preferences.balancesCarousel?.snacks?.findIndex((snackPref: SnackPreference) => snackPref.type === snackType && snackPref.id === removedItem.itemId) >=
				0
			) {
				const filteredSnacks: SnackPreference[] = preferences.balancesCarousel.snacks.filter(
					(snackPref: SnackPreference) => snackPref && snackPref.type !== snackType && snackPref.id !== removedItem.itemId
				);
				currentRequests.push(
					this.updatePreferencesByKey('balancesCarousel', {
						snacks: filteredSnacks,
					})
				);
			}
			const dashboardTabPrefs: SnacksTabData[] = preferences.dashboardTabs;
			const filteredDashBoardTabs = dashboardTabPrefs.filter((tab: SnacksTabData) =>
				tab.snacks.findIndex(
					(snack: DashBoardSnack) =>
						(snack.type === snackType || (snackType === SnackType.recon && <string>snack?.type === 'reconciliation')) && snack.id === removedItem.itemId
				)
			);
			if (filteredDashBoardTabs.length) {
				const tabsCopy: SnacksTabData[] = JSON.parse(JSON.stringify(preferences.dashboardTabs));
				tabsCopy.forEach(
					(tab: SnacksTabData) =>
						(tab.snacks = tab.snacks.filter(
							(snack: DashBoardSnack) =>
								(snack && snack.type !== snackType && (snackType !== SnackType.recon || <string>snack?.type !== 'reconciliation')) ||
								snack.id !== removedItem.itemId
						))
				);
				currentRequests.push(this.updatePreferencesByKey('dashboardTabs', tabsCopy));
			}
		}
		return currentRequests;
	}
}
