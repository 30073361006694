import { AuthState, AuthStateModel } from '../store/state/auth/auth.state';
import { CurrencyState, CurrencyStateModel } from '../../shared/store/state/currency/currency.state';
import { FilterObjectState, FilterObjectStateModel } from 'src/app/shared/store/state/filter-object/filter-object.state';
import { PreferencesState, PreferencesStateModel } from 'src/app/shared/store/state/preferences/preferences.state';
import { ReportsStateModel, ReportsState } from '@trovata/app/features/reports/store/state/reports.state';
import { CoreState, CoreStateModel } from '../store/state/core/core.state';
import { InitCoreState } from '../store/actions/core.actions';
import { ClearReportsState, InitReportsState } from 'src/app/features/reports/store/actions/reports.actions';
import { ClearPreferencesState, InitPreferencesState } from 'src/app/shared/store/actions/preferences.actions';
import { ClearCurrencyState, InitCurrencyState } from 'src/app/shared/store/actions/currency.actions';
import { ClearFilterObjectState, InitFilterObjectState } from 'src/app/shared/store/actions/filter-object.actions';
import { UserConfigState, UserConfigStateModel } from '../store/state/user-config/user-config.state';
import { ClearUserConfigState, InitUserConfigState } from '../store/actions/user-config.actions';
import { PaymentsAccountsState, PaymentsAccountsStateModel } from '../../features/payments/store/state/accounts/accounts.state';
import { TemplatesState, TemplatesStateModel } from '../../features/payments/store/state/templates/templates.state';
import { PaymentsState, PaymentsStateModel } from '../../features/payments/store/state/payments/payments.state';
import { WorkflowsState, WorkflowsStateModel } from '../../features/payments/store/state/workflows/workflow.state';
import { ClearPaymentsAccountsState, InitPaymentsAccountsState } from '../../features/payments/store/actions/accounts.actions';
import { ClearTemplatesState, InitTemplatesState } from '../../features/payments/store/actions/templates.actions';
import { ClearPaymentsState, InitPaymentsState } from '../../features/payments/store/actions/payments.actions';
import { ClearWorkflowsState, InitWorkflowsState } from '../../features/payments/store/actions/workflows.actions';
import { MOCK_ACCOUNTS, MOCK_INSTITUTION_INSTRUCTIONS } from '../../features/payments/models/account.model';
import { MOCK_BATCH_PAYMENTS, MOCK_PAYMENTS } from '../../features/payments/models/payment.model';
import { MOCK_WORKFLOWS } from '../../features/payments/models/workflow.model';
import { TagsState, TagsStateModel } from '../../features/transactions/store/state/tags.state';
import { ClearTagsState, InitTagsState } from '../../features/transactions/store/actions/tags.action';
import { InstitutionsState, InstitutionsStateModel } from '../../shared/store/state/institutions/institutions.state';
import { InitInstitutionsState, ClearInstitutionsState } from '../../shared/store/actions/institutions.actions';
import { ReconciliationState, ReconciliationStateModel } from '../../features/reports/store/state/reconciliation.state';
import { ClearReconciliationState, InitReconciliationState } from '../../features/reports/store/actions/reconciliation.actions';
import { MOCK_TEMPLATES } from '../../features/payments/models/template.model';
import { ForecastV3State, ForecastV3StateModel } from '../../features/forecasts/store/state/forecastV3.state';
import { ClearForecastV3State, InitForecastV3State } from '../../features/forecasts/store/actions/forecastV3.action';
import { ClearCurrentCashState, InitCurrentCashState } from '../../features/balances/store/actions/current-cash.actions';
import { CurrentCashState, CurrentCashStateModel } from '../../features/balances/store/state/current-cash/current-cash.state';
import { CustomerFeatureState, CustomerFeatureStateModel } from '../../features/settings/store/state/customer-feature.state';
import { ClearCustomerFeatureState, InitCustomerFeatureState } from '../../features/settings/store/actions/customer-feature.actions';
import { SettingsState, SettingsStateModel } from '../../features/settings/store/state/settings.state';
import { ClearSettingsState, InitSettingsState } from '../../features/settings/store/actions/settings.actions';
import { IdentityState, IdentityStateModel } from '../../features/settings/store/state/identity.state';
import { ClearIdentityState, InitIdentityState } from '../../features/settings/store/actions/identity.actions';
import { EntitlementsState, EntitlementsStateModel } from '../../features/settings/store/state/entitlements.state';
import { ClearEntitlementsState, InitEntitlementsState } from '../../features/settings/store/actions/entitlements.actions';
import { PlaidState, PlaidStateModel } from '../../features/settings/store/state/plaid.state';
import { ClearPlaidState, InitPlaidState } from '../../features/settings/store/actions/plaid.actions';
import { CustomerUsersState, CustomerUsersStateModel } from '../../features/settings/store/state/customer-users.state';
import { ClearCustomerUsersState, InitCustomerUsersState } from '../../features/settings/store/actions/customer-users.actions';
import { PaymentMethodsState, PaymentMethodsStateModel } from '../../features/settings/store/state/payment-methods.state';
import { ClearPaymentMethodsState, InitPaymentMethodsState } from '../../features/settings/store/actions/payment-methods.actions';
import { TagSnacksState, TagSnacksStateModel } from '../../features/transactions/store/state/tag-snacks.state';
import { ClearTagSnacksState, InitTagSnacksState } from '../../features/transactions/store/actions/tag-snacks.action';
import { SubscriptionsState, SubscriptionsStateModel } from '../../features/settings/store/state/subscriptions.state';
import { ClearSubscriptionsState, InitSubscriptionsState } from '../../features/settings/store/actions/subscriptions.actions';
import { GlTagsState, GlTagsStateModel } from '../../features/transactions/store/state/glTags.state';
import { ClearGlTagsState, InitGlTagsState } from '../../features/transactions/store/actions/glTags.action';
import { GlCodesState, GlCodeStateModel } from '../../features/transactions/store/state/glCode.state';
import { ClearGlCodeState, InitGlCodeState } from '../../features/transactions/store/actions/glCode.action';
import { CashPositionState, CashPositionStateModel } from '../../features/cash-position/store/state/cash-position.state';
import { ClearCashPositionState, InitCashPositionState } from '../../features/cash-position/store/actions/cash-position.actions';
import { ActionType, StateContext } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsentManagementState, ConsentManagementStateModel } from '../../features/settings/store/state/consent-management.state';
import { ClearConsentManagementState, InitConsentManagementState } from '../../features/settings/store/actions/consent-management.actions';
import { ConnectionsState, ConnectionsStateModel } from '../../features/settings/store/state/connections.state';
import { ClearConnectionsState, InitConnectionsState } from '../../features/settings/store/actions/connections.actions';
import { MOCK_AUTH_USER } from './auth.model';
import { TQLFieldsState, TQLFieldsStateModel } from '@trovata/app/shared/store/state/tql-fields/tql-fields.state';
import { ClearTQLFieldsState, InitTQLFieldsState } from '@trovata/app/shared/store/actions/tql-fields.actions';
import { AuditLogsState, AuditLogsStateModel } from '@trovata/app/shared/store/state/audit-logs/audit-logs.state';
import { ClearAuditLogsState, InitAuditLogsState } from '@trovata/app/shared/store/actions/audit-log.actions';
import { InitEntitiesState, ClearEntitiesState } from '../../features/entities/store/actions/entities.actions';
import { EntitiesStateModel, EntitiesState } from '../../features/entities/store/state/entities.state';
import { InitWorkbooksState, ClearWorkbooksState } from '../../features/workbooks/store/actions/workbooks.actions';
import { WorkbooksStateModel, WorkbooksState } from '../../features/workbooks/store/state/workbooks.state';
import { DeveloperPortalApplicationsStateModel, DeveloperPortalApplicationsState } from '../../features/developer-portal/store/state/applications.state';
import {
	InitDeveloperPortalApplicationsState,
	ClearDeveloperPortalApplicationsState,
} from '@trovata/app/features/developer-portal/store/actions/applications.actions';
import { ClearDeveloperPortalResourcesState } from '@trovata/app/features/developer-portal/store/actions/resources.actions';
import { DeveloperPortalResourcesState, DeveloperPortalResourcesStateModel } from '@trovata/app/features/developer-portal/store/state/resources.state';
import { ShareState, ShareStateModel } from '@trovata/app/shared/store/state/shares/share.state';
import { ClearShareState, InitShareState } from '@trovata/app/shared/store/actions/share.actions';
import { InitStatementsState } from 'src/app/features/statements/store/actions/statements.actions';
import { StatementsState, StatementsStateModel } from 'src/app/features/statements/store/state/statements.state';

export const TrovataAppStateKey: string = '@@STATE';

export interface TrovataAppState {
	core: CoreStateModel;
	auth: AuthStateModel;
	userConfig: UserConfigStateModel;
	preferences: PreferencesStateModel;
	filterObject: FilterObjectStateModel;
	cashPosition: CashPositionStateModel;
	currentCash: CurrentCashStateModel;
	currency: CurrencyStateModel;
	reports: ReportsStateModel;
	forecastV3: ForecastV3StateModel;
	reconciliation: ReconciliationStateModel;
	customerFeature: CustomerFeatureStateModel;
	customerUsers: CustomerUsersStateModel;
	tags: TagsStateModel;
	tqlFields: TQLFieldsStateModel;
	glTags: GlTagsStateModel;
	glCodes: GlCodeStateModel;
	tagSnacks: TagSnacksStateModel;
	institutions: InstitutionsStateModel;
	payments: PaymentsStateModel;
	paymentsAccounts: PaymentsAccountsStateModel;
	templates: TemplatesStateModel;
	workflows: WorkflowsStateModel;
	settings: SettingsStateModel;
	identity: IdentityStateModel;
	entitlements: EntitlementsStateModel;
	paymentMethods: PaymentMethodsStateModel;
	plaid: PlaidStateModel;
	subscriptions: SubscriptionsStateModel;
	connections: ConnectionsStateModel;
	consentManagement: ConsentManagementStateModel;
	auditLogs: AuditLogsStateModel;
	entities: EntitiesStateModel;
	workbooks: WorkbooksStateModel;
	developerApplications: DeveloperPortalApplicationsStateModel;
	developerResources: DeveloperPortalResourcesStateModel;
	share: ShareStateModel;
	statements: StatementsStateModel;
}

export type TrovataAppStates = [
	typeof CoreState,
	typeof AuthState,
	typeof UserConfigState,
	typeof PreferencesState,
	typeof FilterObjectState,
	typeof CashPositionState,
	typeof CurrentCashState,
	typeof TagsState,
	typeof TQLFieldsState,
	typeof GlTagsState,
	typeof GlCodesState,
	typeof TagSnacksState,
	typeof CurrencyState,
	typeof ReportsState,
	typeof ForecastV3State,
	typeof ReconciliationState,
	typeof CustomerFeatureState,
	typeof CustomerUsersState,
	typeof InstitutionsState,
	typeof PaymentsState,
	typeof PaymentsAccountsState,
	typeof TemplatesState,
	typeof WorkflowsState,
	typeof SettingsState,
	typeof IdentityState,
	typeof PaymentMethodsState,
	typeof EntitlementsState,
	typeof PlaidState,
	typeof SubscriptionsState,
	typeof ConsentManagementState,
	typeof ConnectionsState,
	typeof AuditLogsState,
	typeof EntitiesState,
	typeof WorkbooksState,
	typeof DeveloperPortalApplicationsState,
	typeof DeveloperPortalResourcesState,
	typeof ShareState,
	typeof StatementsState,
];

export const TROVATA_APP_STATES: TrovataAppStates = [
	CoreState,
	AuthState,
	UserConfigState,
	PreferencesState,
	FilterObjectState,
	CashPositionState,
	CurrentCashState,
	TagsState,
	TQLFieldsState,
	GlTagsState,
	GlCodesState,
	TagSnacksState,
	CurrencyState,
	ReportsState,
	ForecastV3State,
	ReconciliationState,
	CustomerFeatureState,
	CustomerUsersState,
	InstitutionsState,
	PaymentsState,
	PaymentsAccountsState,
	TemplatesState,
	WorkflowsState,
	SettingsState,
	IdentityState,
	PaymentMethodsState,
	EntitlementsState,
	PlaidState,
	SubscriptionsState,
	ConsentManagementState,
	ConnectionsState,
	AuditLogsState,
	EntitiesState,
	WorkbooksState,
	DeveloperPortalApplicationsState,
	DeveloperPortalResourcesState,
	ShareState,
	StatementsState,
];

export type TrovataAppStateToInit =
	| typeof InitCoreState
	| typeof InitUserConfigState
	| typeof InitPreferencesState
	| typeof InitFilterObjectState
	| typeof CashPositionState
	| typeof InitCurrencyState
	| typeof InitReportsState
	| typeof InitForecastV3State
	| typeof InitCurrentCashState
	| typeof InitReconciliationState
	| typeof InitCustomerFeatureState
	| typeof InitCustomerUsersState
	| typeof InitTagsState
	| typeof TQLFieldsState
	| typeof InitGlTagsState
	| typeof InitGlCodeState
	| typeof InitTagSnacksState
	| typeof InitInstitutionsState
	| typeof InitPaymentsState
	| typeof InitPaymentsAccountsState
	| typeof InitTemplatesState
	| typeof InitWorkflowsState
	| typeof InitSettingsState
	| typeof InitIdentityState
	| typeof InitPaymentMethodsState
	| typeof InitEntitlementsState
	| typeof InitPlaidState
	| typeof InitSubscriptionsState
	| typeof InitConsentManagementState
	| typeof InitConnectionsState
	| typeof InitAuditLogsState
	| typeof InitEntitiesState
	| typeof WorkbooksState
	| typeof DeveloperPortalApplicationsState
	| typeof DeveloperPortalResourcesState
	| typeof ShareState
	| typeof StatementsState;

export const TROVATA_APP_STATES_TO_INIT: TrovataAppStateToInit[] = [
	InitCoreState,
	InitUserConfigState,
	InitPreferencesState,
	InitFilterObjectState,
	InitCashPositionState,
	InitCurrencyState,
	InitReportsState,
	InitForecastV3State,
	InitCurrentCashState,
	InitReconciliationState,
	InitCustomerFeatureState,
	InitCustomerUsersState,
	InitTagsState,
	InitGlTagsState,
	InitGlCodeState,
	InitTagSnacksState,
	InitTQLFieldsState,
	InitInstitutionsState,
	InitPaymentsState,
	InitPaymentsAccountsState,
	InitTemplatesState,
	InitWorkflowsState,
	InitSettingsState,
	InitIdentityState,
	InitPaymentMethodsState,
	InitEntitlementsState,
	InitPlaidState,
	InitSubscriptionsState,
	InitConsentManagementState,
	InitConnectionsState,
	InitAuditLogsState,
	InitEntitiesState,
	InitWorkbooksState,
	InitDeveloperPortalApplicationsState,
	InitShareState,
	InitStatementsState,
];

export type TrovataAppStateToClear =
	| typeof ClearUserConfigState
	| typeof ClearPreferencesState
	| typeof ClearFilterObjectState
	| typeof ClearCashPositionState
	| typeof ClearCurrencyState
	| typeof ClearReportsState
	| typeof ClearForecastV3State
	| typeof ClearReconciliationState
	| typeof ClearCustomerFeatureState
	| typeof ClearCustomerUsersState
	| typeof ClearTagsState
	| typeof ClearGlTagsState
	| typeof ClearGlCodeState
	| typeof ClearTagSnacksState
	| typeof ClearTQLFieldsState
	| typeof ClearInstitutionsState
	| typeof ClearCurrentCashState
	| typeof ClearSettingsState
	| typeof ClearIdentityState
	| typeof ClearPaymentMethodsState
	| typeof ClearEntitlementsState
	| typeof ClearPlaidState
	| typeof ClearSubscriptionsState
	| typeof ClearConsentManagementState
	| typeof ClearConnectionsState
	| typeof ClearAuditLogsState
	| typeof ClearEntitiesState
	| typeof ConnectionsState
	| typeof ClearWorkbooksState
	| typeof ClearShareState
	| typeof ClearDeveloperPortalApplicationsState
	| typeof ClearDeveloperPortalResourcesState;

export const TROVATA_APP_STATES_TO_CLEAR: TrovataAppStateToClear[] = [
	ClearUserConfigState,
	ClearPreferencesState,
	ClearFilterObjectState,
	ClearCashPositionState,
	ClearCurrentCashState,
	ClearCurrencyState,
	ClearReportsState,
	ClearForecastV3State,
	ClearReconciliationState,
	ClearCustomerFeatureState,
	ClearCustomerUsersState,
	ClearTagsState,
	ClearTQLFieldsState,
	ClearGlTagsState,
	ClearGlCodeState,
	ClearTagSnacksState,
	ClearInstitutionsState,
	ClearSettingsState,
	ClearIdentityState,
	ClearEntitlementsState,
	ClearPaymentMethodsState,
	ClearPlaidState,
	ClearSubscriptionsState,
	ClearConsentManagementState,
	ClearConnectionsState,
	ClearAuditLogsState,
	ClearEntitiesState,
	ClearWorkbooksState,
	ClearShareState,
	ClearDeveloperPortalApplicationsState,
	ClearDeveloperPortalResourcesState,
];

export const TROVATA_FEATURE_STATES_TO_INIT: TrovataAppStateToInit[] = [
	InitPreferencesState,
	InitFilterObjectState,
	InitCashPositionState,
	InitCurrentCashState,
	InitReportsState,
	InitForecastV3State,
	InitReconciliationState,
	InitTagsState,
	InitTQLFieldsState,
	InitGlTagsState,
	InitGlCodeState,
	InitTagSnacksState,
	InitInstitutionsState,
	InitPlaidState,
	InitConsentManagementState,
	InitAuditLogsState,
	InitEntitiesState,
	InitWorkbooksState,
	InitDeveloperPortalApplicationsState,
	InitShareState,
	InitStatementsState,
];

export const TROVATA_FEATURE_STATES_TO_CLEAR: TrovataAppStateToClear[] = [
	ClearPreferencesState,
	ClearFilterObjectState,
	ClearCashPositionState,
	ClearCurrentCashState,
	ClearReportsState,
	ClearForecastV3State,
	ClearReconciliationState,
	ClearTagsState,
	ClearTQLFieldsState,
	ClearGlTagsState,
	ClearGlCodeState,
	ClearTagSnacksState,
	ClearInstitutionsState,
	ClearPlaidState,
	ClearConsentManagementState,
	ClearAuditLogsState,
	ClearEntitiesState,
	ClearWorkbooksState,
	ClearDeveloperPortalApplicationsState,
	ClearDeveloperPortalResourcesState,
	ClearShareState,
];

export type TrovataPaymentsStatesToInit = typeof InitPaymentsState | typeof InitPaymentsAccountsState | typeof InitTemplatesState | typeof InitWorkflowsState;

export const TROVATA_PAYMENTS_STATES_TO_INIT: TrovataAppStateToInit[] = [InitPaymentsState, InitPaymentsAccountsState, InitTemplatesState, InitWorkflowsState];

export type TrovataPaymentsStateToClear =
	| typeof ClearPaymentsState
	| typeof ClearPaymentsAccountsState
	| typeof ClearTemplatesState
	| typeof ClearWorkflowsState;

export const TROVATA_PAYMENTS_STATES_TO_CLEAR: TrovataPaymentsStateToClear[] = [
	ClearPaymentsState,
	ClearPaymentsAccountsState,
	ClearTemplatesState,
	ClearWorkflowsState,
];

export interface StateApiError {
	stateActionType: string;
	endpoint: string;
	errorMessage: string;
	statusCode: number;
}

export type TrovataAppPaymentsStateModel = PaymentsStateModel | PaymentsAccountsStateModel | TemplatesStateModel | WorkflowsStateModel;

export function checkToRemoveStateApiError<T extends TrovataAppPaymentsStateModel>(context: StateContext<T>, stateAction: ActionType): void {
	const state: T = context.getState();
	state.apiErrors = state.apiErrors.filter((stateApiError: StateApiError) => stateApiError.stateActionType !== stateAction.type);
	context.patchState(state);
}

export function checkToAddStateApiError<T extends TrovataAppPaymentsStateModel>(
	error: HttpErrorResponse | Error,
	context: StateContext<T>,
	stateAction: ActionType,
	customErrorMessage: string
): HttpErrorResponse | Error {
	if (error instanceof HttpErrorResponse) {
		const errorMessage: string = getErrorMessage(error, customErrorMessage);
		const stateApiError: StateApiError = {
			stateActionType: stateAction.type,
			endpoint: error.url,
			errorMessage: errorMessage,
			statusCode: error.status,
		};
		const foundStateApiError: boolean = findStateApiError(context, stateApiError);
		if (!foundStateApiError) {
			const state: T = context.getState();
			state.apiErrors.push(stateApiError);
			state.apiErrors = JSON.parse(JSON.stringify(state.apiErrors));
			context.patchState(state);
		}
	}
	return error;
}

function getErrorMessage(httpError: HttpErrorResponse, customErrorMessage: string): string {
	// TODO: Remove after api errors have been standardized
	let errorMessage: string;
	if (httpError.status === 500 || httpError.status === 0 || isHttpErrorMessageBad(httpError)) {
		errorMessage = customErrorMessage;
	} else if (httpError.error && typeof httpError.error === 'string') {
		errorMessage = httpError.error;
	} else if (httpError.error && httpError.error.error && typeof httpError.error.error === 'string') {
		errorMessage = httpError.error.error;
	} else if (httpError.error && httpError.error.message && typeof httpError.error.message === 'string') {
		errorMessage = httpError.error.message;
	} else {
		errorMessage = customErrorMessage;
	}
	return errorMessage;
}

function findStateApiError<T extends TrovataAppPaymentsStateModel>(context: StateContext<T>, stateApiError: StateApiError): boolean {
	const state: T = context.getState();
	const stateApiErrorString: string = JSON.stringify(stateApiError);
	let foundApiErrorString: boolean = false;
	state.apiErrors.forEach((apiError: StateApiError) => {
		const apiErrorString: string = JSON.stringify(apiError);
		if (stateApiErrorString === apiErrorString) {
			foundApiErrorString = true;
		}
	});
	return foundApiErrorString;
}

function isHttpErrorMessageBad(httpError: HttpErrorResponse): boolean {
	// TODO: Remove after api errors have been standardized
	let httpErrorMessageIsBad: boolean = false;
	if (
		(httpError.error && typeof httpError.error === 'string' && httpError.error.toLowerCase() === 'unknown error') ||
		(httpError.error && httpError.error.error && typeof httpError.error.error === 'string' && httpError.error.error.toLowerCase() === 'unknown error') ||
		(httpError.error && httpError.error.message && typeof httpError.error.message === 'string' && httpError.error.message.toLowerCase() === 'unknown error')
	) {
		httpErrorMessageIsBad = true;
	}
	return httpErrorMessageIsBad;
}

export function checkForBadHttpErrorMessage(httpError: HttpErrorResponse, customErrorMessage: string): HttpErrorResponse {
	// TODO: Remove after api errors have been standardized
	if (httpError.status === 500 || httpError.status === 0 || isHttpErrorMessageBad(httpError)) {
		(httpError as Error).message = customErrorMessage;
	} else {
		(httpError as Error).message = getErrorMessage(httpError, customErrorMessage);
	}
	return httpError;
}

export function openStateApiErrorSnackBar(apiError: StateApiError, startTimeouts: boolean, snackBar: MatSnackBar): void {
	const snackBarTimeOutTime: number = 3000;
	if (!startTimeouts) {
		snackBar.open(apiError.errorMessage, null, {
			duration: snackBarTimeOutTime,
		});
	} else {
		setTimeout(() => {
			snackBar.open(apiError.errorMessage, null, {
				duration: snackBarTimeOutTime,
			});
		}, snackBarTimeOutTime);
	}
}

export function showAnyStateApiErrors(apiErrors: StateApiError[], snackBar: MatSnackBar): void {
	if (apiErrors.length) {
		let startTimeouts: boolean;
		apiErrors.forEach((apiError: StateApiError, index: number) => {
			if (index === 0) {
				startTimeouts = false;
			} else {
				startTimeouts = true;
			}
			openStateApiErrorSnackBar(apiError, startTimeouts, snackBar);
		});
	}
}

export const DEFAULT_ERROR_MESSAGE: string = 'Something went wrong. Please try again later.'; // TODO: Remove all references and replace with UtilityText.tryAgainMsg

export const MOCK_CORE_STATE: CoreStateModel = {
	appReady: true,
	uatEnvironment: false,
};

export const MOCK_PAYMENTS_STATE: PaymentsStateModel = {
	payments: MOCK_PAYMENTS,
	batchPayments: MOCK_BATCH_PAYMENTS,
	tabIndex: 0,
	paymentSnacks: [],
	batchPaymentSnacks: [],
	isCached: true,
	getPaymentsFrom: 0,
	getPaymentsSize: 100,
	getBatchPaymentsFrom: 0,
	getBatchPaymentsSize: 100,
	apiErrors: [],
};

export const MOCK_WORKFLOWS_STATE: WorkflowsStateModel = {
	workflows: MOCK_WORKFLOWS,
	workflowSnacks: [],
	isCached: true,
	apiErrors: [],
};

export const MOCK_ACCOUNTS_STATE: PaymentsAccountsStateModel = {
	accounts: MOCK_ACCOUNTS,
	institutionInstructions: MOCK_INSTITUTION_INSTRUCTIONS,
	accountSnacks: [],
	isCached: true,
	apiErrors: [],
};

export const MOCK_TEMPLATES_STATE: TemplatesStateModel = {
	templates: MOCK_TEMPLATES,
	institutionMap: null,
	countriesDict: null,
	templateSnacks: [],
	isCached: true,
	apiErrors: [],
};

export const MOCK_AUTH_STATE: AuthStateModel = {
	accessToken: 'test',
	refreshToken: 'test',
	authUser: MOCK_AUTH_USER,
	authenticated: true,
	authErrorMessage: 'test',
	loggedIn: false,
};
