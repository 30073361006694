export class Snack {
	name?: string;
	size: SnackSize;
	type: SnackType;
	id: string;
	flipped: boolean;
	view: { chart: boolean; summary: boolean };
	constructor(size: SnackSize, type: SnackType, id?: string, flipped?: boolean, view?: { chart: boolean; summary: boolean }) {
		this.id = id;
		this.size = size;
		this.type = type;
		this.flipped = flipped;
		this.view = view;
	}
}

export class SnackCarousel<T = Snack> {
	title: string;
	identifier: string;
	snacks: T[];
	snackSize: SnackSize;
	newSnackAdded: boolean;
	position: number;
	constructor(snacks: T[], title: string, identifier: string, snackSize: SnackSize, newSnackAdded?: boolean, position?: number) {
		this.title = title;
		this.identifier = identifier;
		this.snacks = snacks;
		this.snackSize = snackSize;
		this.newSnackAdded = newSnackAdded;
		this.position = position;
	}
}

export enum SnackSize {
	small = 'small',
	medium = 'medium',
	large = 'large',
}

export enum SnackType {
	forecast = 'forecast',
	report = 'report',
	recon = 'recon',
	currency = 'currency',
	depreciatedCurrencySummary = 'default3',
	tag = 'tag',
	insight = 'insight',
	currentCash = 'current-cash',
	forecastV3 = 'forecastV3',
	cashPosition = 'cashPosition',
	readyForReview = 'readyForReview',
	statement = 'statement',
}

export enum DemoSnackId {
	forecastDemo = 'forecastDemoId',
	cashFlowDemo = 'cashFlowDemoId',
}
