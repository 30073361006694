import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { combineLatest, firstValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import {
	InitStatementsState,
	GetStatements,
	LoadStatementVersionData,
	DeleteStatement,
	CreateStatement,
	UpdateStatement,
} from 'src/app/features/statements/store/actions/statements.actions';
import { StatementCreateResponse, StatementEntry, StatementEntryVersion } from 'src/app/features/statements/models/statements.models';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { FeatureId } from '../../../settings/models/feature.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StatementsService } from 'src/app/features/statements/services/statements.service';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, StatementUpdatedEvent } from 'src/app/shared/models/updated-events.model';

export class StatementsStateModel {
	statements: StatementEntry[];
	statementVersions: StatementEntryVersion[];
	lastCreatedStatementId: string;
}

@State<StatementsStateModel>({
	name: 'statements',
	defaults: {
		statements: null,
		statementVersions: null,
		lastCreatedStatementId: null,
	},
})
@Injectable()
export class StatementsState {
	private isInitialized: boolean;
	@Select(CustomerFeatureState.hasPermission(FeatureId.statements)) shouldSeeStatements: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private statementsService: StatementsService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.isInitialized = false;
	}

	@Selector() static statements(state: StatementsStateModel): StatementEntry[] {
		return state.statements;
	}

	@Action(InitStatementsState)
	async initStatementsState(context: StateContext<StatementsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const statementsStateIsCached: boolean = this.statementsStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeStatements]).subscribe({
				next: ([appReady, shouldSeeStatements]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady) {
						if (shouldSeeStatements) {
							if (statementsStateIsCached) {
								const state: StatementsStateModel = deserializedState.statements;
								context.patchState(state);
							}
							if (!statementsStateIsCached) {
								context.dispatch(new GetStatements());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ statements: [], statementVersions: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetStatements)
	getStatements(context: StateContext<StatementsStateModel>) {
		return this.statementsService.getStatements().pipe(
			tap((response: HttpResponse<any>) => {
				const statements: StatementEntry[] = response.body.statements;
				this.addStatementsToStatementsState(context, statements);
			})
		);
	}

	private addStatementsToStatementsState(context: StateContext<StatementsStateModel>, statements: StatementEntry[]): void {
		const state: StatementsStateModel = context.getState();
		if (statements && state.statements) {
			const newStatementsToAdd: StatementEntry[] = statements.filter(
				(filterStatement: StatementEntry) =>
					!state.statements.find((findStatement: StatementEntry): boolean => filterStatement.statementId === findStatement.statementId)
			);
			if (newStatementsToAdd.length) {
				state.statements = state.statements.concat(newStatementsToAdd);
			}
		} else if (statements && !state.statements) {
			state.statements = statements;
			context.patchState(state);
		}
	}

	private removeStatementFromStatementsState(context: StateContext<StatementsStateModel>, statementId: string): void {
		const state: StatementsStateModel = context.getState();
		const statements: StatementEntry[] = state.statements;
		const i: number = statements.findIndex(statement => statement.statementId === statementId);
		if (i > -1) {
			statements.splice(i, 1);
		}
		state.statements = statements;
		context.patchState(state);
	}

	private statementsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedStatementsState: StatementsStateModel | undefined = deserializedState.statements;
		if (deserializedStatementsState && deserializedStatementsState.statements) {
			return true;
		} else {
			return false;
		}
	}

	@Action(LoadStatementVersionData)
	loadStatementVersionData(context: StateContext<StatementsStateModel>, action: LoadStatementVersionData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: StatementsStateModel = context.getState();
				const statement: StatementEntry = state.statements.find((findStatement: StatementEntry): boolean => findStatement.statementId === action.statementId);
				await this.loadStatementData(context, statement.statementId);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	private loadStatementData(context: StateContext<StatementsStateModel>, statementId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const state: StatementsStateModel = context.getState();
			try {
				this.statementsService.getStatementById(statementId).subscribe({
					next: (response: HttpResponse<StatementEntry>) => {
						const fullStatement: StatementEntry = response.body;
						const statementIndex: number = state.statements.findIndex((filter: StatementEntry) => statementId === filter.statementId);
						if (statementIndex < 0) {
							state.statements.push(fullStatement);
						} else {
							state.statements[statementIndex] = fullStatement;
						}
						context.patchState({ statements: state.statements });
						resolve();
					},
					error: (error: HttpErrorResponse) => {
						reject(error);
					},
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(CreateStatement)
	createStatement(context: StateContext<StatementsStateModel>, action: CreateStatement): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const response: HttpResponse<StatementCreateResponse> = await firstValueFrom(this.statementsService.createStatement(action.statement));
				await this.loadStatementData(context, response.body.statementId);
				context.patchState({ lastCreatedStatementId: response.body.statementId });
				resolve();
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	}

	@Action(UpdateStatement)
	updateStatement(context: StateContext<StatementsStateModel>, action: UpdateStatement): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.statementsService.updateStatement(action.statement));
				await this.loadStatementData(context, action.statement.statementId);
				resolve();
			} catch (error) {
				console.log(error);
				reject(error);
			}
		});
	}

	@Action(DeleteStatement)
	deleteStatement(context: StateContext<StatementsStateModel>, action: DeleteStatement): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				this.updatedEventsService.updateItem(new StatementUpdatedEvent(ActionType.delete, action.statementId));
				await firstValueFrom(this.statementsService.deleteStatement(action.statementId));
				this.removeStatementFromStatementsState(context, action.statementId);
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Statement'));
			}
		});
	}
}
