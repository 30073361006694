import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { RequestThrottler } from '../../reports/utils/request-throttle';
import { Observable, of, throwError } from 'rxjs';
import { StatementEntry, StatementEntryVersion, StatementsResponse, StatementVersionsResponse } from 'src/app/features/statements/models/statements.models';
import { StatementAPIMockData } from 'src/app/features/statements/services/statement-api-mock-data';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class StatementsService extends RequestThrottler {
	constructor(public httpClient: HttpClient) {
		super(50);
	}

	public getStatements(): Observable<HttpResponse<StatementsResponse>> {
		// const url: string = 'http://localhost:80/workspace-dev/data/v5/statements';
		// return this.httpClient.post<StatementsResponse>(
		// 	url,
		// 	{},
		// 	{
		// 		observe: 'response',
		// 	}
		// );

		// @ts-ignore
		return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getAllStatements() }));
	}

	public getStatementById(statementId: string): Observable<HttpResponse<StatementEntry>> {
		// const url: string = `http://localhost:80/workspace-dev/data/v5/statements/${statementId}`;
		// return this.httpClient.post<StatementEntry>(
		// 	url,
		// 	{},
		// 	{
		// 		observe: 'response',
		// 	}
		// );

		if (statementId === '12345') {
			// @ts-ignore
			return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getStatement1() }));
		} else if (statementId === '21345') {
			// @ts-ignore
			return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getStatement2() }));
		} else if (statementId === '444444') {
			// @ts-ignore
			return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getStatement4() }));
		}
	}

	public getStatementVersions(statementId: string): Observable<HttpResponse<StatementVersionsResponse>> {
		// const url: string = `http://localhost:80/workspace-dev/data/v5/statements/${statementId}/versions`;
		// return this.httpClient.post<StatementVersionsResponse>(
		// 	url,
		// 	{},
		// 	{
		// 		observe: 'response',
		// 	}
		// );

		if (statementId === '12345') {
			// @ts-ignore
			return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getStatement1Versions() }));
		} else if (statementId === '21345') {
			// @ts-ignore
			return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getStatement2Versions() }));
		} else if (statementId === '444444') {
			// @ts-ignore
			return of(new HttpResponse({ status: 200, body: StatementAPIMockData.getStatement2Versions() }));
		}
	}

	public getStatementVersionById(statementId: string, versionId: string): Observable<HttpResponse<StatementEntryVersion>> {
		const url: string = `http://localhost:80/workspace-dev/data/v5/statements/${statementId}/versions/${versionId}`;
		return this.httpClient.post<StatementEntryVersion>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public deleteStatement(statementId: string): Observable<HttpResponse<void>> {
		// const url: string = `http://localhost:80/workspace-dev/data/v5/statements/${statementId}`;
		// return this.httpClient
		// 	.delete<void>(url, {
		// 		observe: 'response',
		// 	})
		// 	.pipe(catchError(err => throwError(err)));

		// @ts-ignore
		return of(new HttpResponse({ status: 200, body: '' }));
	}

	public createStatement(statementPayload: StatementEntry): Observable<HttpResponse<any>> {
		// const url: string = `${environment.trovataAPI('workspace')}/data/v5/statements/statement`;
		const url: string = 'https://api.trovata.io/workspace-uat/data/v5/statements/statement';
		return this.httpClient.post(url, statementPayload, {
			observe: 'response',
		});

		// @ts-ignore
		// return of(new HttpResponse({ status: 200, body: { statementId: '444444' } }));
	}

	public updateStatement(statementPayload: StatementEntry): Observable<HttpResponse<any>> {
		// const url: string = `http://localhost:80/workspace-dev/data/v5/statements/statement/${statementPayload.statementId}`;
		// return this.httpClient.post(url, statementPayload, {
		// 	observe: 'response',
		// });

		// @ts-ignore
		return of(new HttpResponse({ status: 200, body: '' }));
	}
}
