import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { RequestThrottler } from '../../reports/utils/request-throttle';
import { Observable } from 'rxjs';
import { StreamPreviewPayload } from '../models/forecast-stream-preview';
import { IStream, StreamMLDataSourceSettings } from '../models/forecast-stream.model';
import { IFactor } from '../models/forecast-forecast.model';
import { ForecastValues, IForecast, StreamGroupPayload } from '../models/forecast-forecast-response.model';
import { environment } from 'src/environments/environment';
import { ForecastAudit, VersionListResponse } from '../models/forecast-version.model';
import {
	DuplicateForecastPayload,
	DuplicateStreamPayload,
	GetForecastDataRequestBody,
	GetStreamDataRequestBody,
	PostForecastPayload,
} from '../models/forecast-api.model';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';
import { updateForecastsCalendarSettingsDates } from '../models/forecast-calendar-settings';

@Injectable({
	providedIn: 'root',
})
export class ForecastV3Service extends RequestThrottler {
	constructor(private httpClient: HttpClient) {
		super(30);
	}

	// V5
	getForecasts(): Observable<HttpResponse<IForecast>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/forecasts-list`;
		return this.httpClient.post<IForecast>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	getForecastData(getForecastDataRequestBody: GetForecastDataRequestBody, calendarSettings?: CalendarSettings): Observable<HttpResponse<IForecast>> {
		const body: object = {};

		if (getForecastDataRequestBody.versionsToInclude) {
			body['versionIds'] = getForecastDataRequestBody.versionsToInclude;
		}
		if (getForecastDataRequestBody.cadence) {
			body['cadence'] = getForecastDataRequestBody.cadence;
		}
		if (calendarSettings) {
			updateForecastsCalendarSettingsDates(calendarSettings);
			getForecastDataRequestBody.startDate = calendarSettings.startDate;
			getForecastDataRequestBody.endDate = calendarSettings.endDate;
		}
		if (getForecastDataRequestBody.startDate) {
			body['startDate'] = getForecastDataRequestBody.startDate;
		}
		if (getForecastDataRequestBody.endDate) {
			body['endDate'] = getForecastDataRequestBody.endDate;
		}
		if (getForecastDataRequestBody.currency) {
			body['currencyOverride'] = getForecastDataRequestBody.currency;
		}
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${getForecastDataRequestBody.forecastId}`;
		return this.httpClient.post<IForecast>(url, body, {
			observe: 'response',
		});
	}

	getStreams(): Observable<HttpResponse<IStream[]>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams-list`;
		return this.httpClient.post<IStream[]>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	getStreamData(getStreamDataRequestBody: GetStreamDataRequestBody, calendarSettings?: CalendarSettings): Observable<HttpResponse<any>> {
		const body: object = {};

		if (getStreamDataRequestBody.versionsToInclude) {
			body['versionIds'] = getStreamDataRequestBody.versionsToInclude;
		}
		if (getStreamDataRequestBody.cadence) {
			body['cadence'] = getStreamDataRequestBody.cadence;
		}
		if (calendarSettings) {
			updateForecastsCalendarSettingsDates(calendarSettings);
			getStreamDataRequestBody.startDate = calendarSettings.startDate;
			getStreamDataRequestBody.endDate = calendarSettings.endDate;
		}
		if (getStreamDataRequestBody.startDate) {
			body['startDate'] = getStreamDataRequestBody.startDate;
		}
		if (getStreamDataRequestBody.endDate) {
			body['endDate'] = getStreamDataRequestBody.endDate;
		}
		if (getStreamDataRequestBody.currencyOverride) {
			body['currencyOverride'] = getStreamDataRequestBody.currencyOverride;
		}
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${getStreamDataRequestBody.streamId}`;
		return this.httpClient.post(url, body, {
			observe: 'response',
		});
	}

	getForecastVersions(forecastId: string): Observable<HttpResponse<VersionListResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/versions`;
		return this.httpClient.get<VersionListResponse>(url, {
			observe: 'response',
		});
	}

	getStreamVersions(streamId: string): Observable<HttpResponse<VersionListResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${streamId}/versions`;
		return this.httpClient.get<VersionListResponse>(url, {
			observe: 'response',
		});
	}

	createStream(streamPayload: IStream): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams`;
		return this.httpClient.post(url, streamPayload, {
			observe: 'response',
		});
	}

	duplicateStream(streamPayload: DuplicateStreamPayload): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams`;
		return this.httpClient.post(url, streamPayload, {
			observe: 'response',
		});
	}

	createStreamPreview(streamPreviewPayload: StreamPreviewPayload): Observable<HttpResponse<StreamMLDataSourceSettings>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/preview`;
		return this.httpClient.post<StreamMLDataSourceSettings>(url, streamPreviewPayload, {
			observe: 'response',
		});
	}

	createForecast(forecastPayload: PostForecastPayload): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts`;
		return this.httpClient.post(url, forecastPayload, {
			observe: 'response',
		});
	}

	duplicateForecast(forecastPayload: DuplicateForecastPayload): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts`;
		return this.httpClient.post(url, forecastPayload, {
			observe: 'response',
		});
	}

	updateForecast(forecastId: string, forecastPayload: Partial<PostForecastPayload>): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}`;
		return this.httpClient.patch(url, forecastPayload, {
			observe: 'response',
		});
	}

	deleteStream(streamId: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${streamId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	deleteForecast(forecastId: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	getForecastVersionById(forecastId: string, versionId: string, startDate: string, endDate: string, cadence: Cadence): Observable<HttpResponse<IForecast>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/versions/${versionId}`;
		return this.httpClient.post<IForecast>(
			url,
			{ startDate: startDate, endDate: endDate, cadence: cadence },
			{
				observe: 'response',
			}
		);
	}

	getStreamVersionById(streamId: string, versionId: string, startDate: string, endDate: string, cadence: Cadence): Observable<HttpResponse<IForecast>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${streamId}/versions/${versionId}`;
		return this.httpClient.post<IForecast>(
			url,
			{ startDate: startDate, endDate: endDate, cadence: cadence },
			{
				observe: 'response',
			}
		);
	}

	getForecastAudits(forecastId: string): Observable<HttpResponse<ForecastAudit[]>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/audits`;
		return this.httpClient.get<ForecastAudit[]>(url, {
			observe: 'response',
		});
	}

	getStreamAudits(streamId: string): Observable<HttpResponse<ForecastAudit[]>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${streamId}/audits`;
		return this.httpClient.get<ForecastAudit[]>(url, {
			observe: 'response',
		});
	}

	createStreamGroup(forecastId: string, streamGroup: StreamGroupPayload): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/streamGroups`;
		return this.httpClient.post(url, streamGroup, {
			observe: 'response',
		});
	}

	editStreamGroup(forecastId: string, streamGroupId: string, streamGroup: StreamGroupPayload): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/streamGroups/${streamGroupId}`;
		return this.httpClient.patch(url, streamGroup, {
			observe: 'response',
		});
	}

	deleteStreamGroup(forecastId: string, streamGroupId: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/streamGroups/${streamGroupId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	updateForecastVersion(forecastId: string, versionId: string, versionName: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/versions/${versionId}`;
		return this.httpClient.patch(
			url,
			{ name: versionName },
			{
				observe: 'response',
			}
		);
	}

	updateStreamVersion(streamId: string, versionId: string, versionName: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${streamId}/versions/${versionId}`;
		return this.httpClient.patch(
			url,
			{ name: versionName },
			{
				observe: 'response',
			}
		);
	}

	updateStreamData(streamId: string, streamValues: ForecastValues): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/streams/${streamId}/data`;
		return this.httpClient.patch(url, streamValues, {
			observe: 'response',
		});
	}

	updateStream(streamId: string, streamPayload: IStream): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/forecasts/streams/${streamId}`;
		return this.httpClient.patch(url, streamPayload, {
			observe: 'response',
		});
	}

	createGlobalFactor(globalFactorsPayload: IFactor, forecastId: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/factors`;
		return this.httpClient.post(url, globalFactorsPayload, {
			observe: 'response',
		});
	}

	editGlobalFactor(forecastId: string, factorId: string, factorPayload: IFactor): Observable<HttpResponse<any>> {
		factorPayload.stream = undefined; // Fix for API
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/factors/${factorId}`;
		return this.httpClient.patch(url, factorPayload, {
			observe: 'response',
		});
	}

	deleteGlobalFactor(forecastId: string, factorId: string): Observable<HttpResponse<any>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/forecasts/${forecastId}/factors/${factorId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	getAuditsById(auditId: string): Observable<HttpResponse<ForecastAudit>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/forecasts/audits/${auditId}`;
		return this.httpClient.post<ForecastAudit>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}
}
